export const menuItems = [  
  {
    name: "#",
    label: "Kryefaqja",
  },
  {
    name: "lajmet",
    label: "Lajmet",
  },
  {
    name: "kontakt",
    label: "Kontakt",
  },
  
];
